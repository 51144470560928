<template>
	<div>
		<v-dialog v-model="dialog" width="800" persistent>
			<v-card class="overflow-y-auto">
				<v-card-title class="text-h5 grey lighten-2">{{ $t('shipsituation.planning.authorize') }} </v-card-title>

				<v-card-text class="overflow-y-auto">
					<pui-field-set v-for="(stop, index) in stops" :key="stop.id" class="stopsAuthorize ma-2 pa-2" :title="stop.vesselname">
						<v-row>
							<v-col cols="11"> </v-col>
							<v-col cols="1"
								><v-btn icon ripple @click="dropStop(index)">
									<v-icon>far fa-times</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<pui-field-set :title="$t('shipsituation.planning.real')">
							<v-row dense>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.status')" :value="stop.status"></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field
										:label="$t('operations.stops.eta')"
										:value="convertDatesFromStopAIS(stop.eta)"
									></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field
										:label="$t('operations.stops.etd')"
										:value="convertDatesFromStopAIS(stop.etd)"
									></operations-field>
								</v-col>
								<v-col cols="3">
									<operations-field :label="$t('operations.stops.berthname')" :value="stop.berthname"></operations-field>
								</v-col>
								<v-col cols="3">
									<operations-field :label="$t('operations.stops.berthtype')" :value="stop.berthingtypename"></operations-field>
								</v-col>
							</v-row>
						</pui-field-set>
						<pui-field-set :title="$t('shipsituation.planning.requested')">
							<v-row dense>
								<v-col cols="2">
									<operations-field
										:label="$t('shipsituation.resquesteds.authorization.stopPlanned')"
										:value="convertDatesFromStopAIS(stop.entrydate)"
									></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field
										:label="$t('shipsituation.resquesteds.authorization.departurePlanned')"
										:value="convertDatesFromStopAIS(stop.destinationdate)"
									></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.berthname')" :value="stop.berthname"></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.berthtype')" :value="stop.berthingtypename"></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.initialBollard')" :value="stop.inibollard"></operations-field>
								</v-col>
								<v-col cols="2">
									<operations-field :label="$t('operations.stops.endBollard')" :value="stop.endbollard"></operations-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</pui-field-set>
				</v-card-text>
				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="$emit('closeAuthorization')"> {{ $t('shipsituation.resquesteds.authorization.cancel') }} </v-btn>
					<v-btn color="primary" text @click="onOkAuthorizeStops"> {{ $t('shipsituation.resquesteds.authorization.authorize') }} </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import OperationsField from '@/components/operations/map/commonpanel/stop/OperationsField';
/** Constants */
import { REQUESTEDS_PANEL_ID } from '@/lib/variables/panels';

export default {
	name: 'authorizedialog',
	components: { OperationsField },
	mixins: [],
	props: {
		dialog: {
			type: Boolean,
			required: true
		},
		stopsToAuthorize: {
			type: Array,
			default: []
		}
	},

	data() {
		return {
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat,
			stops: []
		};
	},

	created() {},

	computed: {
		dynamicPanels() {
			return this.$store.getters.dynamicPanelsPeriods;
		}
	},

	methods: {
		onOkAuthorizeStops() {
			this.authorize();
		},
		authorize() {
			if (this.stops.length > 0) {
				let url = '/pms/authorizeStops';
				let toAuth = [];
				this.stops.forEach((stop) => {
					toAuth.push({
						portAuthorityId: stop.portauthority,
						portCode: stop.portcode,
						portCallNumber: stop.portcallnumber,
						stopNumber: stop.num,
						berthCode: stop.berthcode,
						bollardIni: stop.inibollard,
						bollardEnd: stop.endbollard,
						etaAuth: stop.entrydate,
						etdAuth: stop.destinationdate,
						berthingType: stop.berthingtype,
						comments: stop.observations
					});
				});

				this.$puiRequests.postRequest(
					url,
					toAuth,
					(response) => {
						console.log(response);
						this.$puiNotify.success(this.$t('shipsituation.resquesteds.authorization.success'));
						// Clean arrays
						this.$store.commit('setStopsFeaturesInstantAU', []);
						this.$store.commit('setStopsFeaturesInstantModifiedAU', []);
						this.$store.commit('setStopsFeaturesInstantWithModifiedAU', []);
						this.$store.commit('setStopsFeaturesInstantRemovedAU', []);

						// Cerramos las pestañas abiertas
						const dynamicTabsToMantain = this.dynamicPanels.filter((obj) => {
							return obj.instantPanel != REQUESTEDS_PANEL_ID;
						});
						this.$store.commit('setDynamicPanelPeriods', dynamicTabsToMantain);

						// Refrescar mapa
						this.$puiEvents.$emit('map:updated');
					},
					(error) => {
						console.log(error.response.data.message);
						this.$puiNotify.error(error.response.data.message);

						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			} else {
				this.$puiNotify.error(this.$puiI18n.t('shipsituation.planning.notSelectedAuthorize'));
			}
			this.$emit('closeAuthorization');
		},
		convertDatesFromStopAIS(date) {
			return this.$dateTimeUtils.getLocalFormattedDate(date, `${this.dateFormat} ${this.timeFormat}`);
		},
		dropStop(index) {
			this.stops.splice(index, 1);
		}
	},

	watch: {
		dialog() {
			this.stops = [];
			this.stopsToAuthorize.forEach((st) => {
				if (st?.selected) {
					this.stops.push(st);
				}
			});
		}
	}
};
</script>

<style lang="postcss" scoped>
.stopsAuthorize {
	border: 1px solid var(--primary);
}
</style>
